import config from 'capacitor.config';

export const environment = {
  production: false,
  mockBackendApi: false,
  capacitorLiveReload: config.server,
  appVersion: require('../../package.json').version,
  backendApi: {
    shared: {
      uri: 'https://iot-dev.verder-scientific.com/shared/api',
      key: 'wC96amRBiet8zHyqggcx9z/P1lcvYueIahZB5KUWWeeluC392aeSkA==',
      id: 'WebApp',
    },
    carbolite: {
      uri: 'https://iot-dev.verder-scientific.com/carbolite/api',
      key: 'z/dYrzc60gw/VVJQiiLnyQsznOoG1updMzbA3OQhxXvUGyY/paVahw==',
      id: 'WebApp',
    },
    eltra: {
      uri: 'https://iot-dev.verder-scientific.com/eltra/api',
      key: 'TZJAi5tfBvO3Q6BexvepjPyGHcPCfwis8PS2e8vLBgs-AzFuoor4kw==',
      id: 'WebApp',
    },
    microtrac: {
      uri: 'https://iot-dev.verder-scientific.com/microtrac/api',
      key: '86PHU17yBSOX93qNLjwq4Z6nkmEz0v6fhyRjv9L/JOlBS4gt0oc9NQ==',
      id: 'WebApp',
    },
    qatm: {
      uri: 'https://iot-dev.verder-scientific.com/qatm/api',
      key: '7ab7iBsyD0KSQVaETg60EQup2Gf8N8UnkNcLToH32XmEEcPnxHUojA==',
      id: 'WebApp',
    },
    retsch: {
      uri: 'https://iot-dev.verder-scientific.com/retsch/api',
      key: 'wLAvt6vkeGP4JuSLpf9A53BmMfQAJRSp1apGGlBLbO9izTAlF2Sf/g==',
      id: 'WebApp',
    },
    retschMockDevice: {
      uri: 'https://retsch.tarekmues.com/device',
      id: 'mockdevice',
    },
  },
  b2cAuth: {
    clientId: '157864ec-61e0-4c57-b79c-6f12297b8188',
    signInName: 'B2C_1_si',
    signUpName: 'B2C_1_sign_up',
    signInAuthority: 'https://verderiotb2cdev.b2clogin.com/verderiotb2cdev.onmicrosoft.com/b2c_1_si',
    signUpAuthority: 'https://verderiotb2cdev.b2clogin.com/verderiotb2cdev.onmicrosoft.com/b2c_1_sign_up',
    resetPasswordAuthority: 'https://verderiotb2cdev.b2clogin.com/verderiotb2cdev.onmicrosoft.com/b2c_1_reset',
    redirectUri: 'https://iot-dev.verder-scientific.com',
    knownAuthorities: ['verderiotb2cdev.b2clogin.com'],
  },
  appInsights: {
    enabled: true,
    instrumentationKey:
      'InstrumentationKey=bad50bd8-bbf2-4857-851e-0328f4ba585a;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
};
